<template>
  <div class="about-page">
    <div class="rn-story-area d-flex " id="about">
      <!-- <div
        class="rn-story-left w-50 rn-story-bg-wrapper"
        :style="{ backgroundImage: 'url()' }"
      ></div> -->
      <div class="rn-story-right w-50 d-flex align-items-center" style="width: 70% !important;">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h1>
              About
            </h1>
            <p>
We are a Photo and Video Production.
Whether it's capturing special moments or creating captivating content, we turn your vision into reality. With a commitment to excellence and a friendly approach, we're here to make your visual dreams come true. Welcome to our creative world!
            </p>
           
            <!-- <div
              class="story-btn  wow newFadeInUp"
              data-wow-duration="1s"
              data-wow-delay="1000ms"
            >
              <router-link to="/contact">Get Connected</router-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>
     <div class="aboutUsPhotos">
              <div class="photo">
                <img src="../../assets/img/bg/sports.jpg" alt="">
              </div>
              <div class="photo">
                <img src="../../assets/img/bg/products.jpg" alt="">
              </div>
              <div class="photo">
                <img src="../../assets/img/categories/Prints-for-sale-Available-SOON/3.jpg" alt="">
              </div>
            </div>
    <!--<div class="timeline-area">
      <div class="timeline-flexbox">
      <div 
      v-for="(item, i) in items"
      :key="i"
      :class="item.id"
      >
      <div :class="i%2==0 ? 'text-wrapper left-text' : 'text-wrapper right-text'">
          <p>
            {{item.textContent}}
          </p>
      </div>
      </div>
    </div>
    </div>-->
  </div>
</template>

<script>
import { gsap } from 'gsap'

  export default {
    components: {
    },
    data() {
      return {
        //aboutSrc: require("../../assets/img/portfolio/about-image.jpg"),
        //skillSrc: require("../../assets/img/portfolio/my-skill.jpg"),
         items: [
        {
          id: 'element1',
          color: 'red-lighten-2',
          icon: 'mdi-star',
          textContent: 'Photography and visual identity have been a huge part of our life since we were kids. We always liked street photography and movies and had a very artsy vision towards life, but unfortunately, like many kids in our environment, the idea to pursue Photography or any artistic field as a career just was never an option. ',
        },
        {
          id: 'element2',
          color: 'purple-lighten-2',
          icon: 'mdi-book-variant',
          textContent: 'That’s mainly due to the fact that the people around us saw success and achievement in working a 9 to 5 and saving money to eventually buy a house, a car and settle down. We never felt that it was enough, but it was all we were presented with.',
        },
        {
          id: 'element3',
          color: 'green-lighten-1',
          icon: 'mdi-airballoon',
          textContent: 'It was later in life that we realized, it is possible for us to achieve great things beyond a 9 to 5, and that we can actually do what we love full-time and be very successful at it. That’s when we started our journey towards “Change”, and it’s been the toughest thing we ever decided to do.',
        },
        {
          id: 'element4',
          color: 'indigo-lighten-2',
          icon: 'mdi-layers-triple',
          textContent: 'It’s not easy to change all your pre-judgments and beliefs regarding how life works. It is not easy to change a cultural mindset that is bound to fail. Especially that our goal is not personal change only, but we plan to make it spread to our environment, as we pledge to have a positive impact on our people, no matter how little. We need big change in this industry, we deserve it, the people deserve it.',
        },
        {
          id: 'element5',
          color: 'indigo-lighten-2',
          icon: 'mdi-layers-triple',
          textContent: 'I strongly believe that we, Africans and Middle Easterns, have the ability to take this to the highest levels worldwide, and WE WILL. That\'s why we need to work together, support each other and learn from each other so we can grow as a community.',
        },
        {
          id: 'element6',
          color: 'indigo-lighten-2',
          icon: 'mdi-layers-triple',
          textContent: 'There is an African proverb by Martha Goedert that says "If you want to go fast, go alone, if you want to go far, go together", and I promise you all, nothing is as true.  That’s where the identity of Younousvisuals came from, it’s not just a Photo and Video production; it’s a revolution, a refresh, a change.',
        },
      ],
      };
    },
    methods: {
       addAppearOnScrollAnimation(className)
       {
        // const body = document.querySelector('body');
        
        gsap.set('.'+className, { opacity: 1 }); 

        gsap.from('.'+className, {
          opacity: 0,
          duration: 2,
          scrollTrigger: {
            trigger: '.'+className,
            start: 'top 80%',
            end: 'bottom 20%',
            toggleActions: 'play none none reverse',
            onEnter: () => {
              document.querySelector('body').classList.add(className+'-bg');
            },
            onLeaveBack: () => {
              document.querySelector('body').classList.remove(className+'-bg');
            },
              },
            });
       }
    },
    mounted() {
      // this.addAppearOnScrollAnimation('element1');
      // this.addAppearOnScrollAnimation('element2');
      // this.addAppearOnScrollAnimation('element3');
      // this.addAppearOnScrollAnimation('element4');
      // this.addAppearOnScrollAnimation('element5');
      // this.addAppearOnScrollAnimation('element6');
  },
  };
</script>

<style lang="scss" scoped>
  .about-page {
    .rn-skill-right {
      @media only screen and (max-width: 767px) {
        order: 2;
      }
    }
  }
  //   @font-face {
  // font-family: 'Futura';
  // src: url('/assets/fonts/Futura PT Book.ttf') format('ttf');
  // }
     h1 {
    // font-family: Futura !important; 
    font-weight: 100;
    font-style: normal;
    font-size: 57px;
    letter-spacing: .07em;
    line-height: .9em;
    text-transform: none;
    margin-bottom:38px;
  }
  .content p {
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    letter-spacing: .06em;
    line-height: 1.6em;
    text-transform: none;
    color: #000;
  }
  body *{
    // font-family: Futura !important; 
  }
  .aboutUsPhotos {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    gap: 10px;
    padding: 0 100px;
    width: 100%;
  }
  .aboutUsPhotos .photo {
    min-width: calc(30%);
    // min-width: 200px;
    // max-width: 300px;
  }
  .photo img {
    width: 100%;
  }
  .story-style--3.story-content {
    padding-bottom: 0px !important;
        margin-bottom: 38px;
  }
  .timeline-area {
     padding: 100px 100px 0 100px;
  }
  .timeline-box {
    width: 80%;
    margin: 0 auto;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: initial !important ;
  }
  
</style>
