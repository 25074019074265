<template>
  <div>
    <HeaderFive>
      <!--<img slot="logo" src="../assets/img/logo/YOUNOUS-black.png" /> -->

    </HeaderFive>
    <div class="about_page-main">
      <About />
      <!-- End About -->
    </div>

    <Footer />
    <!-- End Footer -->
  </div>
</template>

<script>
  import HeaderFive from "../components/header/HeaderFive";
  import About from "../components/about/About";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      HeaderFive,
      About,
      Footer
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss">
  .about_page-main {
    .content h2 {
      font-size: 36px;
      line-height: 1.5;
      margin-bottom: 28px;

      @media only screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }
</style>
